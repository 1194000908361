@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Work+Sans:wght@400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.home-banner {
  background-color: #fff5ed;
  background-image: url("./assets/banner.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.services-banner {
  background-image: url("./assets/services.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.wheat-banner {
  background-color: #fdf5eb;
  background-image: url("./assets/wheat-banner.png");
  background-position: center;

  background-size: contain;
}

.carousel .control-dots .dot {
  background: #f29c52 !important;
}

footer {
  background-image: url("./assets//footer.png");
  background-position: center;
  background-size: cover;
}
